<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <a :target="targetBlank" :href="buttonUrl" class="article2-item">
    <v-card width="300">
      <div class="relative">
        <v-img
          :src="photoUrl"
          :alt="photoName"
          :aspect-ratio="432 / 275"
        ></v-img>
        <ecvWaveCard />
        <div class="article2-white-block"></div>
      </div>
      <v-card-text>
        <div class="px-3 py-4">
          <h2
            class="
              article-item__title
              secondary--text
              mb-5
              tmw-22-10
              text-overflow-ep-2
            "
          >
            {{ title }}
          </h2>
          <TypeB-btn
            checkLink
            :to="buttonUrl"
            linkOut
            :targetBlank="targetBlank"
            :overflowEp="1"
            color="primary"
            >{{ buttonText }}</TypeB-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </a>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import owlItemMixins from "@/components/owl/owlItem.js";
import ecvWaveCard from "@/components/wave/ecvWaveCard.vue";
export default {
  mixins: [owlItemMixins],
  components: {
    ecvWaveCard,
  },
};
</script>
