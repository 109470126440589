<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <svg
    id="ecvWaveCard"
    class="ecvWaveCard"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 300 16"
  >
    <path
      id="Path_642"
      class="ecvWaveCard"
      d="M0,5.8C28,2,56.1,0.1,84.3,0C129.2,0,209.6,11.3,234,11.3s74.2-0.8,103-5.6v10.6H0L0,5.8z"
    />
  </svg>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
// export default {
//   mounted() {
//     alert($(window).width())
//     console.log(this.$el)
//   },
// }
</script>
